<template>
  <svg
    width="24"
    height="19"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6201 14.4697C13.4948 14.4202 13.3553 14.4202 13.2301 14.4697L11.7601 14.9697V2.52973L16.1201 1.19973V9.03973C16.1433 9.15523 16.2058 9.25914 16.2969 9.33379C16.3881 9.40844 16.5022 9.44923 16.6201 9.44923C16.7379 9.44923 16.8521 9.40844 16.9432 9.33379C17.0344 9.25914 17.0968 9.15523 17.1201 9.03973V0.469725C17.1217 0.391826 17.1043 0.314701 17.0695 0.24501C17.0346 0.17532 16.9834 0.115159 16.9201 0.0697253C16.8575 0.0321956 16.7873 0.00912782 16.7146 0.00220729C16.6419 -0.00471323 16.5686 0.0046884 16.5001 0.0297253L11.2501 1.61973L6.03006 0.0297253C5.94177 0.00496554 5.84836 0.00496554 5.76006 0.0297253L0.360063 1.66973C0.256205 1.70002 0.164974 1.76318 0.100063 1.84973C0.0351516 1.93627 6.26871e-05 2.04154 6.26871e-05 2.14973V15.6597C-0.00122207 15.74 0.017259 15.8194 0.0538788 15.8909C0.0904986 15.9624 0.144134 16.0238 0.210063 16.0697C0.272508 16.1194 0.346357 16.1528 0.42493 16.1667C0.503502 16.1807 0.584317 16.1748 0.660063 16.1497L5.88006 14.5297L11.1001 16.1497C11.1917 16.1748 11.2884 16.1748 11.3801 16.1497L13.5601 15.4697C13.6843 15.4198 13.7869 15.3278 13.8501 15.2097C13.8996 15.0845 13.8996 14.945 13.8501 14.8197C13.8206 14.6781 13.7384 14.553 13.6201 14.4697ZM6.39006 1.19973L10.7501 2.52973V14.9797L6.39006 13.6297V1.19973ZM1.02006 14.9797V2.52973L5.37006 1.19973V13.6297L1.02006 14.9797Z"
      fill="#FAE9EC"
    />
    <path
      d="M22.77 17.6303L20.85 15.7503C20.7742 15.6785 20.6846 15.6228 20.5866 15.5867C20.4886 15.5506 20.3843 15.5348 20.28 15.5403L19.99 15.2403C20.4501 14.6325 20.6912 13.8871 20.6742 13.125C20.6572 12.3629 20.3831 11.629 19.8965 11.0423C19.4098 10.4556 18.7391 10.0507 17.9932 9.89322C17.2474 9.73576 16.4703 9.83503 15.788 10.1749C15.1057 10.5148 14.5583 11.0753 14.2347 11.7655C13.9111 12.4557 13.8303 13.235 14.0055 13.9768C14.1806 14.7187 14.6013 15.3796 15.1994 15.8522C15.7975 16.3249 16.5377 16.5814 17.3 16.5803C18.0172 16.5919 18.7189 16.3709 19.3 15.9503L19.6 16.2503C19.5937 16.3585 19.6099 16.4669 19.6478 16.5685C19.6857 16.6701 19.7443 16.7627 19.82 16.8403L21.75 18.7203C21.8945 18.8609 22.089 18.9383 22.2906 18.9355C22.4922 18.9326 22.6844 18.8499 22.825 18.7053C22.9656 18.5608 23.043 18.3663 23.0401 18.1647C23.0373 17.9631 22.9545 17.7709 22.81 17.6303H22.77ZM14.94 13.2003C14.94 12.7311 15.0793 12.2725 15.3401 11.8826C15.601 11.4926 15.9717 11.1889 16.4054 11.0098C16.839 10.8307 17.3161 10.7843 17.7761 10.8766C18.2361 10.9689 18.6583 11.1956 18.9894 11.528C19.3204 11.8605 19.5454 12.2837 19.6357 12.7441C19.726 13.2045 19.6776 13.6813 19.4967 14.1142C19.3158 14.5471 19.0105 14.9166 18.6194 15.1758C18.2284 15.435 17.7692 15.5723 17.3 15.5703C16.6732 15.5677 16.0729 15.3168 15.6306 14.8726C15.1883 14.4285 14.94 13.8271 14.94 13.2003V13.2003Z"
      fill="#FAE9EC"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
